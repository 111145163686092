import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { TableContainer, TablePagination } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../../../../common/components/Title";
import { BASE_URL } from "../../../../api/config";
import { Reservation } from "../../../../../types/reservation";
import { useUserContext } from "../../../../context/UserContext";
import dayjs from "dayjs";
import YellowButton from "common/components/YellowButton";

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function Reservations() {
  const { user } = useUserContext();
  const [status] = useState("SUCCESS");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [reservations, setReservations] = useState<Array<Reservation>>([]);

  useEffect(() => {
    if (user) {
      const query = `?status=${status}`;
      fetch(BASE_URL + "reservations" + query, {
        headers: {
          "x-auth-token": user.token,
        },
      })
        .then((data) => {
          if (data.status === 200) {
            return data.json();
          }
          throw new Error("Algo malo ocurrió");
        })
        .then((response) => setReservations(response as Array<Reservation>));
    }
  }, [status, user, page, rowsPerPage]);

  const handleSubmit = () => {
    if (user) {
      fetch(BASE_URL + "report/getReport", {
        method: "POST",
        headers: {
          "x-auth-token": user.token,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.blob(); // Convert the response to a blob
          }
          throw new Error("Algo malo ocurrió");
        })
        .then((blob) => {
          // Create a URL for the blob
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "report.csv"); // Set the download attribute
          document.body.appendChild(link);
          link.click(); // Trigger the download
          document.body.removeChild(link); // Remove the link from the DOM
        })
        .catch((error) => {
          console.error("Error downloading the file:", error);
        });
    }
  };

  const columns = [
    { field: "session_id", headerName: "Order Id", minWidth: 150 },
    { field: "name", headerName: "Nombre", minWidth: 150 },
    { field: "email", headerName: "Email", minWidth: 150 },
    { field: "phone", headerName: "Teléfono", minWidth: 150 },
    { field: "program.name", headerName: "Programa", minWidth: 150 },
    { field: "room.name", headerName: "Habitación", minWidth: 150 },
    { field: "start_date", headerName: "Checkin", minWidth: 150 },
    { field: "end_date", headerName: "Checkout", minWidth: 150 },
    {
      field: "purchase_date",
      headerName: "Fecha de transacción",
      minWidth: 150,
    },
    { field: "adults", headerName: "N de adultos", minWidth: 150 },
    { field: "number_of_rooms", headerName: "N de habitación", minWidth: 150 },
    { field: "total_price", headerName: "Precio total", minWidth: 150 },
    {
      field: "transaction_id",
      headerName: "Codigo de Autorización",
      minWidth: 150,
    },
  ];

  return (
    <>
      <Title>Reservas</Title>
      <YellowButton
        className="button-descarga"
        handleSubmit={handleSubmit}
        text="Descargar Reporte"
      />
      {/*<TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Order Id</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>Programa</TableCell>
              <TableCell>Habitación</TableCell>
              <TableCell>Checkin</TableCell>
              <TableCell>Checkout</TableCell>
              <TableCell>Fecha de transacción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reservations.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.session_id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.program?.name}</TableCell>
                <TableCell>{row.room?.name}</TableCell>
                <TableCell>{row.start_date}</TableCell>
                <TableCell>{row.end_date}</TableCell>
                <TableCell>
                  {row.purchase_date
                    ? dayjs(row.purchase_date).format("YYYY-MM-DD HH:mm")
                    : ""}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>*/}

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell className="column">{column.headerName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {reservations
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  {columns.map((column) => {
                    let fieldValue = null;
                    if (column.field.includes(".")) {
                      const [parentField, childField] = column.field.split(".");
                      if (
                        (row[parentField as keyof Reservation] as any)?.[
                          childField
                        ]
                      ) {
                        fieldValue = (
                          row[parentField as keyof Reservation] as any
                        )[childField as keyof typeof row.id];
                      }
                    } else {
                      fieldValue = row[column.field as keyof Reservation];
                    }

                    // Formatear fecha si es necesario
                    if (
                      column.field === "start_date" ||
                      column.field === "end_date" ||
                      column.field === "purchase_date"
                    ) {
                      fieldValue = dayjs(fieldValue).format("YYYY-MM-DD HH:mm");
                    }

                    return (
                      <TableCell key={column.field}>{fieldValue}</TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {/* Agregar paginación */}
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25, 100]}
        count={reservations.length}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) =>
          setRowsPerPage(parseInt(event.target.value, 10))
        }
      />
    </>
  );
}
