import { FC, useMemo, useState } from "react"
import { Button, Grid, InputAdornment, Paper, Table, TableCell, TableRow, TextField, Typography } from "@mui/material"
import { useBookingContext } from "../../../context/BookingContext"
import YellowButton from "../../../common/components/YellowButton";
import { BASE_URL } from "../../../api/config";
import { BookingContextActionEnum } from "../../../enum";
import dayjs from "dayjs";
import { formatDate } from "../../../utils/helpers";

const Summary: FC = () => {
  const { state, dispatch } = useBookingContext();
  const [coupon, setCoupon] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const price = useMemo(() => {
    const days = state.startDate && state.endDate ? Math.ceil(state.endDate.diff(state.startDate, 'd', true)) : 0;
    let roomsPrice = state.selectedRooms ? state.selectedRooms.reduce((prev, curr) => prev + curr.price, 0) : 0;
    roomsPrice += state.selectedRooms ? state.selectedRooms.reduce((prev, curr) => prev += curr.additionalBedPrice, 0) : 0;
    return roomsPrice * days;
  }, [state.endDate, state.selectedRooms, state.startDate]);

  const guests = useMemo(() => {
    const adults = `${state.guests.adults} ${state.guests.adults > 1 ? 'adultos' : 'adulto'}`;
    const children = state.guests.children > 0 ? ` / ${state.guests.children} ${state.guests.children > 1 ? 'niños' : 'niño'}` : '';
    const infants = state.guests.infants > 0 ? ` / ${state.guests.infants} ${state.guests.infants > 1 ? 'infantes' : 'infante'}` : '';
    return adults + children + infants;
  }, [state.guests.adults, state.guests.children, state.guests.infants]);

  const discount = useMemo(() => {
    return state.coupon ? (price * state.coupon.discount)/100 : 0
  }, [price, state.coupon])

  function capitalizeFirstLetter(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  const handleCoupon = () => {
    fetch(`${BASE_URL}coupons/${coupon}?start_date=${formatDate(state.startDate)}&end_date=${formatDate(state.endDate)}&program_id=${state.selectedProgram?.id}`)
      .then(data => {
        if(data.status === 200){
          return data.json()
        }
        throw new Error('Cupón inválido');
      })
      .then(response => dispatch({
        type: BookingContextActionEnum.ADD_COUPON,
        payload: {
          coupon: response.coupon
        }
      }))
      .catch(e => {
        setHasError(true);
      });

  };

  return (
    <Paper>
      <Grid container sx={{ padding: "30px 30px 10px 30px" }}>
        <Grid item xs={12} justifyContent="center" marginBottom={4}>
          <Typography fontWeight="bold" textAlign="center">RESUMEN</Typography>
        </Grid>
        <Grid item xs={12}>
          <Table >
            <TableRow>
              <TableCell align='left' >
                <Typography>Programa</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography>{state.selectedProgram && state.selectedProgram.name}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left' >
                <Typography>Habitación</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography>{state.selectedRooms?.[0].name}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left' >
                <Typography>Check In</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography>{state.startDate?.format("YYYY-MM-DD")}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left' >
                <Typography>Check out</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography>{state.endDate?.format("YYYY-MM-DD")}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left' >
                <Typography>Huéspedes</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography>{guests}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left' >
                <Typography>Subtotal</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography>${new Intl.NumberFormat('es-CL').format(price)}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left' >
                <Typography>Descuento</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography>${new Intl.NumberFormat('es-CL').format(discount)}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left' >
                <Typography fontWeight="bold">Total</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography fontWeight="bold">${new Intl.NumberFormat('es-CL').format(price - discount)}</Typography>
              </TableCell>
            </TableRow>
          </Table>
        </Grid>
        <Grid marginTop={2}>
          <TextField
            id="code"
            label="Código de Descuento"
            type="text"
            error={hasError}
            helperText={hasError ? 'Cupón inválido' : ''}
            value={coupon}
            onChange={(e) => {
              setCoupon(e.target.value);
              setHasError(false);
            }}
            InputProps={{ endAdornment: <YellowButton text="Validar" handleSubmit={handleCoupon}></YellowButton> }}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Summary;