import { Button, Switch, TableCell, TableRow } from "@mui/material";
import { FC, useState } from "react";
import { ProgramRowProps } from "../../../../../types/dashboard";
import { ProgramInput } from "../../../../../types/context";
import ProgramModal from "./ProgramModal";
import { useUserContext } from "../../../../context/UserContext";
import { updateProgram } from "../../../../api/programs";

const ProgramRow: FC<ProgramRowProps> = (props: ProgramRowProps) => {
  const { user } = useUserContext();
  const { program } = props;
  const [programInput, setProgramInput] = useState<ProgramInput>({
    ...program,
    rooms: program.rooms ? program.rooms.map((room) => room.id) : [],
    program_days: program.program_days
      ? program.program_days.map((pd) => String(pd.day_id))
      : [],
  });

  const [isActive, setIsActive] = useState(program.is_active);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleProgramInput = (
    key: keyof typeof programInput,
    value: string | number | boolean | string[]
  ) =>
    setProgramInput((prev) => {
      if (key === "program_days") {
        return {
          ...prev,
          program_days:
            typeof value === "string" ? value.split(",") : (value as string[]),
        };
      }
      return { ...prev, [key]: value };
    });

  const cleanDescription = (description: string | undefined) => {
    if (description) {
      return description.includes("<li>")
        ? description
            .replaceAll("<li>", "")
            .replaceAll("</li>", "\n")
            .slice(0, -1)
        : description;
    }

    return "";
  };

  const cleanPolicies = (cancellation_policies: string | undefined) => {
    if (cancellation_policies) {
      return cancellation_policies.includes("<li>")
        ? cancellation_policies
            .replaceAll("<li>", "")
            .replaceAll("</li>", "\n")
            .slice(0, -1)
        : cancellation_policies;
    }

    return "";
  };

  const cleanTerms = (general_terms: string | undefined) => {
    if (general_terms) {
      return general_terms.includes("<li>")
        ? general_terms
            .replaceAll("<li>", "")
            .replaceAll("</li>", "\n")
            .slice(0, -1)
        : general_terms;
    }

    return "";
  };

  const handleProgramStatus = () => {
    if (user) {
      const newStatus = !isActive;
      updateProgram(
        user.token,
        { is_active: newStatus },
        String(program.id)
      ).catch((e) => console.log(e));
      setIsActive(newStatus);
    }
  };

  return (
    <>
      <ProgramModal
        id={program.id}
        description={cleanDescription(programInput.description)}
        details={programInput.details}
        duration={programInput.duration}
        handleChange={handleProgramInput}
        handleClose={handleClose}
        hasAvailability={programInput.hasAvailability}
        image={programInput.image}
        isOpen={open}
        is_active={programInput.is_active}
        is_special_day={programInput.is_special_day}
        name={programInput.name}
        not_allowed={programInput.not_allowed}
        program_days={programInput.program_days}
        suggested={programInput.suggested}
        cancellation_policies={cleanPolicies(
          programInput.cancellation_policies
        )}
        general_terms={cleanTerms(programInput.general_terms)}
        isEditing={true}
      />
      <TableRow key={`${program.id}`}>
        <TableCell>{program.name}</TableCell>
        <TableCell>
          <Switch checked={isActive} onChange={handleProgramStatus} />
        </TableCell>
        <TableCell width={200}>
          <Button onClick={handleOpen}>Editar</Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProgramRow;
