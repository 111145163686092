import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  ThemeProvider,
  Radio,
  Typography,
  CircularProgress,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import CustomStepper from "../../common/components/CustomStepper";
import Header from "../../common/components/Header";
import YellowButton from "../../common/components/YellowButton";
import { StyledContainer } from "../step1/step1-styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import calendarTheme from "../../themes/calendar";
import { BASE_URL } from "../../api/config";
import { useBookingContext } from "../../context/BookingContext";
import { useNavigate } from "react-router-dom";

const Step4: FC = () => {
  const { state } = useBookingContext();
  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState({
    token: "",
    url: "",
  });
  const [checked, setChecked] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [isValidating, setIsValidating] = useState(true);

  const formRef = useRef<HTMLFormElement>(null);
  const handleSubmit = () => {
    console.log(formRef);
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  useEffect(() => {
    if (!state.selectedProgram) {
      navigate("/");
    }
  }, [navigate, state.selectedProgram]);
  useEffect(() => {}, [selectedValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedValue(value);
    const reservationRequest = {
      name: state.userData.name,
      phone: state.userData.phone,
      email: state.userData.email,
      adults: state.guests.adults,
      children: state.guests.children,
      infants: state.guests.infants,
      room_id: state.selectedRooms?.[0]?.id,
      program_id: state.selectedProgram?.id,
      start_date: state.startDate?.format("YYYY-MM-DD"),
      end_date: state.endDate?.format("YYYY-MM-DD"),
      room_configuration: state.room_configuration,
      comment: state.userData.comment || "Sin comentario",
      coupon: state.coupon?.code || "",
      method_payment: value,
    };
    setIsValidating(true);
    fetch(BASE_URL + "reservations/create", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reservationRequest),
    })
      .then((data) => data.json())
      .then((response) =>
        setPaymentData({
          token: response.token,
          url: response.url,
        })
      )
      .then((response) => setIsValidating(false))
      .catch((err) => console.log(err));
  };
  return (
    // Pasar esto a componente layout
    <StyledContainer maxWidth={false} disableGutters>
      <Header opacity={0.8} />
      <CustomStepper activeStep={3} />
      <Grid container padding={4} justifyContent="center" spacing={8}>
        <Grid item xs={12}>
          <Paper>
            <Grid container padding={2} marginRight={2}>
              <Grid item xs={12} marginBottom={2}>
                <Typography fontWeight="bold" textAlign="center">
                  TERMINOS GENERALES
                </Typography>
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography textAlign="center">
                      CONDICIONALES GENERALES
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paper
                      variant="outlined"
                      sx={{
                        border: "1px dashed black",
                      }}
                    >
                      {state.selectedProgram?.general_terms ? (
                        <ul
                          dangerouslySetInnerHTML={{
                            __html: state.selectedProgram?.general_terms,
                          }}
                        />
                      ) : (
                        <ul>
                          <li>
                            Garantía de ingreso: al momento de su llegada deberá
                            dejar obligatoriamente una garantía en recepción con
                            tarjeta de crédito.
                          </li>
                          <li>
                            NO SE RECIBEN PAGOS EN EFECTIVO, EN NINGÚN PUNTO DE
                            VENTA DEL HOTEL.
                          </li>
                          <li>
                            Se necesitarán los nombres completos, números de
                            pasaportes o cédula de identidad y mail de contacto
                            de todos los pasajeros.
                          </li>
                          <li>
                            No se permite el ingreso de ningún tipo de
                            comestibles ni bebestibles.
                          </li>
                          <li>No se permite ingreso de mascotas al recinto.</li>
                          <li>
                            En caso de no cumplir con la hora de salida
                            establecida, el Hotel está facultado para realizar
                            un cargo de una noche adicional a su tarjeta de
                            crédito.
                          </li>
                          <li>
                            Está prohibido fumar en habitaciones y sus balcones.
                            En caso de ser reportado este hecho por nuestro
                            Departamento de Housekeeping, se aplicará una multa
                            equivalente a 100 USD, por concepto de sanitización
                            y desodorización.
                          </li>
                          <li>
                            Todo objeto olvidado será guardado durante 90 días.
                            De no ser retirado durante este plazo, será
                            entregado a una institución de beneficencia.
                          </li>
                        </ul>
                      )}
                    </Paper>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography textAlign="center">
                      POLÍTICAS DE ANULACIÓN
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paper
                      variant="outlined"
                      sx={{
                        padding: "8px",
                        border: "1px dashed black",
                      }}
                    >
                      {state.selectedProgram?.cancellation_policies ? (
                        <ul
                          dangerouslySetInnerHTML={{
                            __html:
                              state.selectedProgram?.cancellation_policies,
                          }}
                        />
                      ) : (
                        <ul>
                          <li>
                            En caso de no concurrir en la fecha establecida, su
                            reserva estará sujeta a cobro por parte del Hotel en
                            el equivalente a una noche como NO SHOW por
                            habitación.
                          </li>
                          <li>
                            En caso de que el huésped a su llegada decida
                            retirarse, el hotel está facultado a realizar el
                            cargo a su tarjeta de crédito, correspondiente a una
                            noche como NO SHOW por habitación.
                          </li>
                          <li>
                            Toda anulación deberá ser solicitada vía e-mail a
                            reservas@jahuel.cl , indicando nombre del pasajero.
                          </li>
                        </ul>
                      )}
                    </Paper>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Typography textAlign="left">
                    ACEPTAR TÉRMINOS Y CONDICIONES
                  </Typography>
                </Grid>
                <Grid item xs={12} marginBottom={2}>
                  <Typography textAlign="left">
                    Por favor revise con atención las políticas de cancelación.
                  </Typography>
                </Grid>
                <Grid item xs={12} marginBottom={2}>
                  <Typography textAlign="left" fontWeight="bold">
                    INFORMACIÓN IMPORTANTE: “COMUNICAMOS A TODOS NUESTROS
                    HUÉSPEDES, QUE DESDE EL 01 DE DICIEMBRE 2022, TERMAS DE
                    JAHUEL S.A. CAMBIA SU DENOMINACIÓN O RAZÓN SOCIAL A
                    EMBOTELLADORA DE AGUAS JAHUEL S.A”
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ThemeProvider theme={calendarTheme}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onClick={() => setChecked(!checked)}
                        />
                      }
                      label="He leído y acepto las políticas de cancelación de Termas de Jahuel."
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12} marginBottom={2}>
                  <Typography
                    textAlign="center"
                    fontWeight="bold"
                    marginBottom={2}
                  >
                    Elige tu método de pago
                  </Typography>
                  {/*<Typography textAlign="center">
                    Paga el total de su cuenta en linea en khipu. Si presenta
                    algún problema utilice alternativa "otros canales".
                    </Typography>*/}
                  <Typography textAlign="center">
                    Pronto habilitaremos otro medio de pago
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  marginBottom={2}
                  justifyContent="center"
                  textAlign="center"
                >
                  {/*  <FormControlLabel
                    control={
                      <Radio
                        value="khipu"
                        checked={selectedValue === "khipu"}
                        onChange={handleChange}
                      />
                    }
                    label={
                      <img src="/images/khipu.png" alt="metodo de pago khipu" />
                    }
                  />*/}
                  <FormControlLabel
                    control={
                      <Radio
                        value="otros"
                        checked={selectedValue === "otros"}
                        onChange={handleChange}
                      />
                    }
                    label={
                      <img
                        src="/images/logo-webpay.png"
                        alt="metodo de pago webpay"
                      />
                    }
                  />
                </Grid>
                <Grid
                  item
                  container
                  mt={2}
                  xs={12}
                  justifyContent="center"
                  textAlign="center"
                  marginBottom={2}
                >
                  <form ref={formRef} action={paymentData.url} method="POST">
                    <input
                      type="hidden"
                      name="token_ws"
                      value={paymentData.token}
                    />
                    {isValidating ? (
                      <CircularProgress color="inherit" /> // Reemplaza esto con tu indicador de carga
                    ) : paymentData.url ? (
                      <YellowButton
                        text="Pagar"
                        handleSubmit={handleSubmit}
                        disabled={!checked}
                      />
                    ) : null}
                  </form>
                </Grid>
              </Grid>
            </Grid>
            <img
              alt="jahuel-logo"
              src="/images/logo-white.png"
              height={100}
              width={100}
            />
          </Paper>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Step4;
