import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../api/config";
import YellowButton from "../../../common/components/YellowButton";
import { WhiteTextField } from "../../../common/styles/textfields";
import { useBookingContext } from "../../../context/BookingContext";
import { useSnackbarContext } from "../../../context/SnackbarContext";
import { BookingContextActionEnum } from "../../../enum";
import AddOrSubstractInput from "./AddOrSubstractInput";

const GuestForm: FC = () => {
  const { state, dispatch } = useBookingContext();
  console.log(state);
  const { showSnackBar } = useSnackbarContext();
  let navigate = useNavigate();

  const handleAddAdult = () => {
    dispatch({
      type: BookingContextActionEnum.ADD_ADULT,
    });
  };
  const handleRemoveAdult = () => {
    dispatch({
      type: BookingContextActionEnum.REMOVE_ADULT,
    });
  };
  const handleAddChildren = () => {
    dispatch({
      type: BookingContextActionEnum.ADD_CHILD,
    });
  };
  const handleRemoveChildren = () => {
    dispatch({
      type: BookingContextActionEnum.REMOVE_CHILD,
    });
  };
  const handleAddInfant = () => {
    dispatch({
      type: BookingContextActionEnum.ADD_INFANT,
    });
  };
  const handleRemoveInfant = () => {
    dispatch({
      type: BookingContextActionEnum.REMOVE_INFANT,
    });
  };

  // agregar cantidad de habitaciones por programa, configurable
  const handleSubmit = () => {
    // snackbars de error por si no ha seleccionado opciones que debería
    if (state.startDate && state.endDate) {
      const startDate = state.startDate.format("YYYY-MM-DD");
      const endDate = state.endDate.format("YYYY-MM-DD");
      const query = `?startDate=${startDate}&endDate=${endDate}`;
      if (startDate === endDate) {
        return showSnackBar(true, "Las fechas deben ser distintas");
      }
      if (startDate > endDate) {
        return showSnackBar(
          true,
          "La fecha de inicio debe ser menor a la de término"
        );
      }
      if (state.guests.adults === 0) {
        return showSnackBar(
          true,
          "La cantidad de adultos debe ser mayor a cero"
        );
      }
      if (startDate !== endDate && state.guests.adults > 0) {
        fetch(BASE_URL + "programs" + query, {
          method: "GET",
        })
          .then((data) => data.json())
          .then((response) => {
            if (response.programs.length === 0) {
              return showSnackBar(
                true,
                "No contamos con disponibilidad para la fecha solicitada, por favor seleccionar otra fecha o contactarse a reservas +56224111720 / reservas@jahuel.cl"
              );
            }
            dispatch({
              type: BookingContextActionEnum.ADD_PROGRAMS,
              payload: {
                programs: response.programs,
              },
            });
            if (response.suggested) {
              dispatch({
                type: BookingContextActionEnum.ADD_START_DATE,
                payload: {
                  date: dayjs(response.startDate),
                },
              });
              dispatch({
                type: BookingContextActionEnum.ADD_END_DATE,
                payload: {
                  date: dayjs(response.endDate),
                },
              });
              navigate("/sugerencias");
            } else {
              navigate("/programas");
            }
          })
          .catch((e) =>
            showSnackBar(
              true,
              "Ha ocurrido un error. Por favor intente nuevamente"
            )
          );
      }
    }
  };

  return (
    <Grid container display="flex" flexDirection="column">
      <Typography color="primary" marginBottom={4} fontSize={18}>
        Huéspedes
      </Typography>

      <WhiteTextField
        color="primary"
        id="adultos"
        label="13 años en adelante"
        defaultValue="ADULTOS"
        InputProps={{
          readOnly: true,
          color: "primary",
          endAdornment: (
            <AddOrSubstractInput
              max={8}
              handleAdd={handleAddAdult}
              handleRemove={handleRemoveAdult}
              counter={state.guests.adults}
            />
          ),
        }}
        variant="standard"
        sx={{
          input: {
            color: "#fff",
          },
        }}
      />
      <WhiteTextField
        color="primary"
        id="niños"
        label="4 a 12 años"
        defaultValue="NIÑOS"
        InputProps={{
          readOnly: true,
          color: "primary",
          endAdornment: (
            <AddOrSubstractInput
              max={4}
              handleAdd={handleAddChildren}
              handleRemove={handleRemoveChildren}
              counter={state.guests.children}
            />
          ),
        }}
        variant="standard"
        sx={{
          input: {
            color: "#fff",
          },
        }}
      />
      <WhiteTextField
        color="primary"
        id="infantes"
        label="De 3 años o menos"
        defaultValue="INFANTES"
        InputProps={{
          readOnly: true,
          color: "primary",
          endAdornment: (
            <AddOrSubstractInput
              max={2}
              handleAdd={handleAddInfant}
              handleRemove={handleRemoveInfant}
              counter={state.guests.infants}
            />
          ),
        }}
        variant="standard"
        sx={{
          input: {
            color: "#fff",
          },
        }}
      />

      <Grid container justifyContent="center">
        <YellowButton handleSubmit={handleSubmit} text="Siguiente" />
      </Grid>
    </Grid>
  );
};

export default GuestForm;
