import { BASE_URL } from "./config";

export const getAllSpecialDays = async (token: string) => {
  const apiCall = await fetch(BASE_URL + "special-days/", {
    headers: { "x-auth-token": token },
  });

  const response = await apiCall.json();

  return response;
};

export const createSpecialDay = async (token: string, body: unknown) => {
  const apiCall = await fetch(BASE_URL + "special-days", {
    headers: { "x-auth-token": token, "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify(body),
  });

  const response = await apiCall.json();

  return response?.data;
};

export const updateSpecialDay = async (
  token: string,
  body: unknown,
  id: string
) => {
  const apiCall = await fetch(`${BASE_URL}special-days/${id}`, {
    headers: { "x-auth-token": token, "Content-Type": "application/json" },
    method: "PUT",
    mode: "no-cors",
    body: JSON.stringify(body),
  });

  const response = await apiCall.json();

  return response?.data;
};

export const deleteSpecialDay = async (token: string, id: string) => {
  const apiCall = await fetch(`${BASE_URL}special-days/${id}`, {
    headers: { "x-auth-token": token, "Content-Type": "application/json" },
    method: "DELETE",
    mode: "no-cors",
  });

  const response = await apiCall.json();

  return response?.data;
};
