import styled from "@emotion/styled";
import { Container, Card, Typography } from "@mui/material";

export const StyledContainer = styled(Container)({
  backgroundColor: "#F5F5F5",
  minHeight: "100vh",
  height: "100%",
});

export const StyledCard = styled(Card)({
  backgroundColor: "#31313175 !important",
  padding: "20px",
  position: "relative",
  top: "0.75rem",
  width: "96%",
  color: "white",
});

export const StyledTypographic = styled(Typography)({
  fontWeight: 700,
});
