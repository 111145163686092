import App from "./App";
import { UserContextProvider } from "./context/UserContext";
import "./index.css";
import ReactDOM from "react-dom";

ReactDOM.render(
  <UserContextProvider>
    <App />
  </UserContextProvider>,
  document.querySelector("#root")
);
