export const days = [
  {
    id: "0",
    name: "Domingo",
  },
  {
    id: "1",
    name: "lunes",
  },
  {
    id: "2",
    name: "martes",
  },
  {
    id: "3",
    name: "miercoles",
  },
  {
    id: "4",
    name: "Jueves",
  },
  {
    id: "5",
    name: "viernes",
  },
  {
    id: "6",
    name: "Sabado",
  },
];
