import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { ChangeEvent, FC, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { style } from "../../../../common/styles/modal";
import Title from "../../../../common/components/Title";
import ProgramCard from "../../../step1/components/ProgramCard";
import { days } from "../../../../common/constants";
import { useUserContext } from "../../../../context/UserContext";
import { ProgramInput } from "../../../../../types/context";
import { updateProgram, createProgram } from "../../../../api/programs";
import { formatNumber } from "../../../../utils/helpers";
import { PhotoCamera } from "@mui/icons-material";
import { useSnackbarContext } from "context/SnackbarContext";

type ProgramModalProps = {
  id?: string;
  name?: string;
  image?: string;
  is_active?: boolean;
  description?: string;
  hasAvailability?: boolean;
  is_special_day?: boolean;
  program_days?: Array<string>;
  details?: string;
  duration?: number;
  suggested?: boolean;
  not_allowed?: string;
  handleChange: (
    key: keyof ProgramInput,
    value: string | boolean | string[] | number
  ) => void;
  isOpen: boolean;
  handleClose: () => void;
  isEditing?: boolean;
  handleReloadPrograms?: (token: string) => void;
  cancellation_policies?: string;
  general_terms?: string;
};

interface UploadState {
  photo: string;
  name: string;
}

const Input = styled("input")({
  display: "none",
});

const ProgramModal: FC<ProgramModalProps> = (props: ProgramModalProps) => {
  const {
    id,
    name,
    isOpen,
    description,
    hasAvailability,
    image,
    is_active,
    is_special_day,
    program_days,
    details,
    duration,
    suggested,
    not_allowed,
    handleClose,
    handleChange,
    isEditing,
    handleReloadPrograms,
    cancellation_policies,
    general_terms,
  } = props;

  const { user } = useUserContext();
  const reader = new FileReader();
  const { showSnackBar } = useSnackbarContext();
  const [upload, SetUpload] = useState<UploadState>({ photo: "", name: "" });
  const [expanded, setExpanded] = useState("panel1");

  const handleChangeAccordion =
    (panel: any) => (event: any, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleDurationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = formatNumber(e.target.value);
    if ((value || value === 0) && value >= 0) {
      return handleChange("duration", value);
    }

    handleChange("duration", duration || 0);
  };
  const mapDescriptionToLi = (description: string | undefined) =>
    description
      ? description
          .split("\n")
          .map((item) => `<li>${item}</li>`)
          .join("")
      : "";

  const mapCancellPoliciesToLi = (cancellation_policies: string | undefined) =>
    cancellation_policies
      ? cancellation_policies
          .split("\n")
          .map((item) => `<li>${item}</li>`)
          .join("")
      : "";

  const mapGeneralTermnsToLi = (general_terms: string | undefined) =>
    general_terms
      ? general_terms
          .split("\n")
          .map((item) => `<li>${item}</li>`)
          .join("")
      : "";

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.size > 1 * 1024 * 1024) {
      alert(
        "El archivo es demasiado grande. Debe ser menor a 1MB. Por favor intente bajarle el peso a la imagen."
      );
      return;
    }
    reader.onerror = function () {
      console.error("Error al leer el archivo:", reader.error);
    };
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        console.log(reader);
        let images = {
          photo: reader.result,
          name: file.name,
        };
        SetUpload(images);
      } else {
        showSnackBar(
          true,
          "No se pudo procesar la img, por favor comuniquese con su administrador de paginas webs"
        );
      }
    };
    reader.readAsDataURL(file);
  };

  const program = {
    name,
    description: mapDescriptionToLi(description),
    hasAvailability,
    is_active,
    is_special_day,
    program_days,
    details,
    duration,
    suggested,
    not_allowed,
    image: image ?? "programa-pausa.jpg",
    upload,
    cancellation_policies: mapCancellPoliciesToLi(cancellation_policies),
    general_terms: mapGeneralTermnsToLi(general_terms),
  };

  console.log(program, "aca van los programas");

  const handleCreateOrUpdateProgram = () => {
    if (user) {
      const action = id
        ? updateProgram(user.token, program, id)
        : createProgram(user.token, program);

      action
        .then((data) => {
          handleClose();
          handleReloadPrograms?.(user.token);
          window.location.reload();
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h4"
          component="h2"
          marginBottom={4}
        >
          {isEditing ? "Editar Programa" : "Nuevo Programa"}
        </Typography>
        <Box
          component="form"
          display="flex"
          flexDirection="row"
          noValidate
          autoComplete="off"
        >
          <div style={{ width: "50%" }}>
            <div style={{ marginBottom: "20px" }}>
              <TextField
                sx={{
                  minWidth: "90%",
                  marginBottom: 1,
                }}
                required
                id="name"
                label="Nombre"
                placeholder="Nombre del Programa"
                variant="standard"
                value={name}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange("name", e.target.value)
                }
              />
              <TextField
                sx={{
                  minWidth: "90%",
                  marginBottom: 1,
                }}
                required
                id="image"
                label="Imagen"
                placeholder="Nombre de imagen"
                variant="standard"
                helperText="Si quiere usar una imagen por defecto, dejar vacío"
                value={image}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange("image", e.target.value)
                }
              />
              {
                // Descomentar cuando se implementé un servicio que guarde imágenes
                /* <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                multiple
                                type="file"
                            />
                            <label htmlFor="raised-button-file">
                                <Button variant='contained' component="span" sx={{ marginTop: 2 }}>
                                Subir imagen
                                </Button>
                            </label> */
              }
            </div>
            <TextField
              sx={{
                minWidth: "90%",
              }}
              fullWidth
              id="description"
              label="Descripción"
              placeholder="Descripción del programa"
              multiline
              helperText='Los saltos de línea marcan los elementos de la lista desplegable. Esto corresponde a la sección "Ver lo que incluye"'
              value={description}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange("description", e.target.value)
              }
            />
            <FormGroup sx={{ marginBottom: 4 }}>
              <FormControlLabel
                control={
                  <Switch
                    value={is_active}
                    checked={is_active}
                    onClick={() => handleChange("is_active", !is_active)}
                  />
                }
                label="Activo"
              />
              <FormControlLabel
                control={
                  <Switch
                    value={hasAvailability}
                    checked={hasAvailability}
                    onClick={() =>
                      handleChange("hasAvailability", !hasAvailability)
                    }
                  />
                }
                label="Siempre disponible"
              />
              <FormControlLabel
                control={
                  <Switch
                    value={is_special_day}
                    checked={is_special_day}
                    onClick={() =>
                      handleChange("is_special_day", !is_special_day)
                    }
                  />
                }
                label="Fin de semana largo"
              />
            </FormGroup>

            <TextField
              sx={{
                minWidth: "20%",
                marginBottom: 4,
              }}
              id="duration"
              label="Duración"
              placeholder="Duración del programa"
              helperText="Valida que la diferencia entre inicio y fin sea exactamente la duración. Dejar en cero para saltar."
              value={duration}
              onChange={handleDurationChange}
            />

            <InputLabel id="days-label">Días en que aplica</InputLabel>
            <Select
              sx={{
                marginBottom: "24px",
                width: "90%",
              }}
              labelId="days-label"
              id="days-dropdown"
              value={program_days || []}
              label="Días"
              multiple
              renderValue={(selected) =>
                selected
                  .map((val) => days.find((item) => item.id === val)?.name)
                  .join(", ")
              }
              onChange={(e: SelectChangeEvent<string[]>) =>
                handleChange("program_days", e.target.value)
              }
            >
              {days &&
                days.length > 0 &&
                days.map((day) => (
                  <MenuItem value={day.id}>
                    <Checkbox
                      checked={
                        program_days && program_days.indexOf(day.id) > -1
                      }
                    />
                    <ListItemText primary={day.name} />
                  </MenuItem>
                ))}
            </Select>
            {/* Aca va las cancelaciones y la politicas de los usuarios */}
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChangeAccordion("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Términos Generales
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Descripción de los términos generales
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  sx={{
                    minWidth: "90%",
                  }}
                  fullWidth
                  id="general_terms"
                  label="general_terms"
                  placeholder="Descripción de los terminos generales"
                  multiline
                  helperText='Los saltos de línea marcan los elementos de la lista desplegable. Esto corresponde a la sección "Ver lo que incluye"'
                  value={general_terms}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChange("general_terms", e.target.value)
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChangeAccordion("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Políticas de cancelación
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Descripción de las Políticas de cancelación
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  sx={{
                    minWidth: "90%",
                  }}
                  fullWidth
                  id="cancellation_policies"
                  label="cancellation_policies"
                  placeholder="Descripción de los terminos generales"
                  multiline
                  helperText='Los saltos de línea marcan los elementos de la lista desplegable. Esto corresponde a la sección "Ver lo que incluye"'
                  value={cancellation_policies}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChange("cancellation_policies", e.target.value)
                  }
                />
              </AccordionDetails>
            </Accordion>
          </div>

          <hr />
          <Grid style={{ width: "45%" }}>
            <Title>Vista Previa</Title>
            <ProgramCard
              title={name || ""}
              program={{
                id: "1",
                name: name || "",
                image: image || "programa-pausa.jpg",
                is_active: is_active || false,
                description: mapDescriptionToLi(description),
                details: details || "",
                duration: duration || 0,
                hasAvailability: hasAvailability || false,
                rooms: [],
                suggested: suggested || false,
                not_allowed: not_allowed,
              }}
            />
            <label className="upload-picture" htmlFor="icon-button-file">
              <Input
                id="icon-button-file"
                type="file"
                accept="image/png,image/jpeg"
                onChange={handleFileChange}
              />
              <IconButton
                className="icon-photo"
                aria-label="upload picture"
                component="span"
              >
                <PhotoCamera />
              </IconButton>
            </label>
            {upload.photo ? (
              <div className="photo-new">
                <img src={upload.photo} className="img-upload" alt="imagen" />
                <h5>Previsualizacion de la img</h5>
              </div>
            ) : null}
            <Grid marginTop={2} display="flex" justifyContent="center">
              <Button
                variant="contained"
                onClick={handleCreateOrUpdateProgram}
                style={{
                  width: "50%",
                }}
              >
                {isEditing ? "Editar" : "Crear"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProgramModal;
